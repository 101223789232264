.beehive {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
    z-index: 0;

    .beehive-wrap {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        height: 110%;
        width: 110%;
    }

    .beehive-item {
        background-color: #fff;
        display: inline-block;
        width: calc(8.333vw - 20px);
        height: calc(7vw - 20px);
        -webkit-clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
        clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
        position: relative;

        &::before {
            content: "";
            background-color: $primary-white;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: calc(100% + 10px);
            height: calc(100% + 10px);
        }

        .beehive-title {
            color: $primary-light;
            font-weight: bold;
            position: absolute;
            top: 50%;
            left: 50%;
            text-align: center;
            transform: translate(-50%,-50%);
            width: 100%;
        }
    }
}