@import '../custom/theme-variables';
@import '../custom/mixins';

/* ------------------------
    Header
------------------------*/

.header {
  background-color: #fff;
  position: relative;
  z-index: 10;
}

/*--Header top--*/

.topbar-link {
  font-size: 14px;

  i {
    font-size: 20px;
    margin-right: 10px;
  }

  a:hover {
    color: $primary;
  }
}

.header-top .social-icons li a {
  background: rgba(255, 255, 255, 0.2);
  height: 30px;
  width: 30px;
  line-height: 30px;
  color: $text-body;
}

/*--Header Main--*/

#header-wrap {
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;

  &.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999 !important;
    background: $white;
    @include animation(smoothScroll 1s forwards);
  }
}

@keyframes smoothScroll {
  0% {
    @include transform(translateY(-40px));
  }

  100% {
    @include transform(translateY(0px));
  }
}

.navbar {
  padding: 0;
}

.navbar-nav .nav-item {
  margin: 0 15px;

  &.dropdown .dropdown-menu {
    border-radius: 0;
    padding: 20px;
    background: $white;
    top: 100%;
    border: none;
    margin-top: 0;
    //left: -100%;
    @include box-shadow(0 0.5rem 1rem rgba(42, 39, 94, 0.06));
    width: calc(100% + 200px) !important;
  }
}

.navbar-brand {
  margin-right: 0;
  padding: 0;
}

.nav-item.dropdown .dropdown-menu a {
  display: block;
  color: $text-dark;
  padding: 0;
  padding-top: 6px;
  padding-bottom: 6px;
  white-space: nowrap;
  position: relative;
  background: none;
  font-size: 15px;
  font-weight: 400;

  &:hover {
    color: $primary;
    padding-left: 15px;
  }
}

#header-wrap.fixed-header .navbar-brand {
  color: $white;
}

@media (min-width: 1200px) {
  .navbar-collapse {
    width: auto;
  }

  .navbar-expand-lg {
    justify-content: space-between;
  }

  .navbar-nav {
    .dropdown-menu {
      min-width: 12em !important;
    }

    .dropdown {
      > .dropdown-menu {
        opacity: 0;
        position: absolute;
        display: block;
        pointer-events: none;
        background: $white;
        @include transition(all 0.5s ease-in-out 0s);
        @include transition(opacity 0.5s ease-in-out);
      }

      &:hover > .dropdown-menu {
        display: block;
        opacity: 1;
        pointer-events: auto;
        left: 0;
      }
    }

    .dropdown-submenu:hover .dropdown-menu {
      top: 0;
      display: block;
    }
  }

  .nav-link.dropdown-toggle {
    &::after {
      border: solid $text-dark;
      border-width: 0 1px 1px 0;
      width: 0.425rem;
      height: 0.425rem;
      @include transform(rotate(45deg));
      vertical-align: 0.15em;
    }

    &:hover::after {
      @include transform(rotate(-135deg));
    }
  }

  .navbar-nav {
    .nav-link {
      line-height: 80px;
    }

    .dropdown-submenu .dropdown-toggle::after {
      border-top: 0.125rem solid $text-dark;
      border-right: 0.125rem solid $text-dark;
      border-bottom: none;
      border-left: none;
      width: 0.425rem;
      height: 0.425rem;
      position: absolute;
      right: 0;
      margin: 0;
      vertical-align: middle;
      top: 50%;
      @include transform(translateY(-50%) rotate(45deg));
    }
  }
}

@media (max-width: 991.98px) {
  .navbar-nav .dropdown-submenu .dropdown-menu {
    padding: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    top: 0;
  }
}

.navbar-nav {
  .dropdown-menu .dropdown-submenu .dropdown-menu {
    background: $white;
    left: 100%;
    margin: 0;
    right: auto;
    top: 0;
  }

  .dropdown-submenu {
    display: block;
    position: relative;
  }

  .nav-link {
    color: $text-dark;
    font-size: 16px;
    padding: 0 !important;
    border-radius: 0;
    text-transform: capitalize;
    position: relative;
    font-weight: 500;

    &:hover {
      color: $primary;
    }
  }

  .nav-item .nav-link.active {
    color: $primary;
  }
}

.language-selection {
  button {
    box-shadow: none;
    border: none;
    background: none;
    color: $text-body;
    font-size: 14px;
    padding: 0;
  }

  .dropdown-menu {
    min-width: inherit;
    padding: 0;
  }

  .dropdown-item {
    font-size: 14px;
    padding: 5px 15px;
  }

  .dropdown-toggle::after {
    vertical-align: middle;
  }
}

/*--Header Search--*/

.search-icon a {
  font-size: 30px;
  color: $text-dark;
  line-height: 30px;
}

#search-input-box {
  position: fixed;
  left: 50%;
  width: 100%;
  max-width: 1140px;
  z-index: 999;
  text-align: center;
  background: $bg-dark;
  z-index: 9999;
}

.search-inner {
  padding: 5px 5px;
}

#search-input-box {
  .form-control {
    background: transparent;
    border: 0;
    color: $white;
    font-weight: 400;
    font-size: 15px;
    padding: 0;
  }

  .btn {
    width: 0;
    height: 0;
    padding: 0;
    border: 0;
  }

  #close-search {
    color: $white;
    font-weight: 600;
    cursor: pointer;
    padding: 10px;
    padding-right: 10px;
    padding-right: 0;
  }
}

.right-nav {
  position: relative;
}

/*--Header Cart--*/

.cart {
  position: relative;
  z-index: 200;
}

[data-cart-items] {
  position: relative;
  display: inline-block;

  &::before {
    content: attr(data-cart-items);
    position: absolute;
    top: -.5rem;
    right: -.75rem;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    font-size: 11px;
    font-weight: 500;
    border-radius: 50%;
    background-color: $primary;
    color: $white;
  }
}

.cart a i {
  font-size: 26px;
  color: $text-dark;
}

/* ------------------------
Modal Popup
------------------------*/

.modal.cart-modal {
  padding-right: 0 !important;

  .modal-dialog {
    margin: 0 0 0 auto;
    height: 100%;
    max-width: 450px;
  }

  .modal-content {
    border: none;
    border-radius: 0;
    height: inherit;
    overflow-y: auto;
  }

  &.fade .modal-dialog {
    @include transform(translateX(100%));
  }

  &.show .modal-dialog {
    @include transform(translateX(0));
  }
}

.modal-header .close {
  background: $primary;
  color: $white;
  opacity: 1;
}