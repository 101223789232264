$font-primary: 'Montserrat', sans-serif;
$font-title: 'Montserrat', sans-serif;

$primary: #4fa5dc;
$primary-light: #88c2e7;
$primary-white: #ddedf8;
$secondary: #242b56;
$secondary-light: #384386;
$secondary-white: #e9ebf6;
$text-hover: $secondary;
$text-dark: $secondary;
$text-body: #527395;
$white: #ffffff;
$text-rgba: rgba(255, 255, 255, 0.8);


$border-primary:  #4fa5dc;
$border-light:  #e9eaed;

$bg-dark: #0a1426;
$bg-light: #f5f8fd;