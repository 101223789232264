.card-rotate {
    cursor: pointer;
    display: inline-block;
    margin-bottom: 15px;
    perspective: 150rem;
    position: relative;
    height: 325px;
    box-shadow: none;
    background: none;
    width: 100%;

    .card-side {
        height: 325px;
        transition: all 0.8s ease;
        backface-visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        padding:2rem;
        width: 100%;
    }
    
    .card-side.back {
        background-color: #fff;
        border: 4px solid $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        text-align: center;
        transform: rotateY(-180deg);

        &::before {
            content: "";
            background-image: url(/storage/icones/bee-neovd-primary.svg);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            opacity: .1;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            height: 67%;
            width: 50%;
            z-index: 0;
        }

        & > * {
            position: relative;
            z-index: 1;
    }
    
        ul {
            list-style: none;
            margin-bottom: 0;
            li {
                color: $primary;
                font-size: 15px;
                line-height: 1.2;
                font-weight: bold;
                position: relative;
            }
            li:not(:last-child) {
                margin-bottom: 5px;
                padding-bottom: 15px;
                &::after {
                    content: "";
                    background-image: url('/storage/neo-full-dash.svg');
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    height: 10px;
                    width: 50px;
                    transform: translateX(-50%);
                }
            }
        }
    }
      
    .card-side.front {
        background-color: $primary;
        background-image: linear-gradient(160deg, rgba($secondary, .3) 0%, transparent);
        display: flex;
        align-items: center;
        justify-content: center;
    
        &::before {
            content: "";
            background: url('/storage/backgrounds/hexagons-pattern-2-1.svg') center left no-repeat;
            background-size: cover;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    
        .card-title {
            color: #fff;
            font-size: 25px;
            font-weight: 900;
            text-align: center;
        }
    }
    
    &:hover .card-side.front {
        transform: rotateY(180deg);
    }
      
    &:hover .card-side.back {
        transform: rotateY(0deg);
    }
}


.card-rotate-2 {
    cursor: pointer;
    display: inline-block;
    margin-bottom: 15px;
    perspective: 150rem;
    position: relative;
    width: 300px;
    height: 325px;
    box-shadow: none;
    background: none;

    .card-side {
        width: 100%;
        height: 100%;
        transition: all 0.8s ease;
        backface-visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        padding:2rem;
        width: 100%;
    }
    
    .card-side.back {
        background: url('/storage/hexagon-style-bg-2.svg') center center no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        transform: rotateY(-180deg);

        span {
            color: #fff;
            font-size: 22px;
            font-weight: bold;
        }
    }
      
    .card-side.front {
        background: url('/storage/hexagon-style-bg.svg') center center no-repeat;
        background-size: cover;
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
    
        .card-image {
            margin: 0 auto 10px auto;
            width: 65%;
        }

        .card-title {
            color: $primary;
            font-size: 18px;
            line-height: 1.2;
            font-weight: 900;
            text-align: center;
        }
    }
    
    &:hover .card-side.front {
        transform: rotateY(180deg);
    }
      
    &:hover .card-side.back {
        transform: rotateY(0deg);
    }
}