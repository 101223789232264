@import '../custom/theme-variables';
@import '../custom/mixins';

/* ------------------------------------------------
  Project:   Labortech - Laboratory & Science Research HTML5 Template
  Author:    ThemeHt
------------------------------------------------ */

/* ------------------------
    Table of Contents

  1. General
  2. Transition
  3. Transform translate
  4. Box Shadow
  5. Pre Loader
  6. Background Overlay
  7. Scroll to Top
  8. Header
  9. Footer
  10. Page Title
  11. Flat Icon

 
/* ------------------------
    General
------------------------*/

a {
  text-decoration: none !important;

  &:focus {
    text-decoration: none !important;
    color: $primary;
    text-decoration: none !important;
  }

  &:hover {
    color: $primary;
    text-decoration: none !important;
  }

  outline: medium none !important;
  color: $primary;
}

button, input {
  outline: medium none !important;
  color: $primary;
}

*::-moz-selection, ::-moz-selection, ::selection {
  background: $primary;
  color: $white;
  text-shadow: none;
}

/* ------------------------
    Transition
------------------------*/

a, i, .btn, button, img, span, .dropdown-item, .nav-link.dropdown-toggle::after, .social-icons li:hover a, .owl-item, .service-item, .service-desc, .service-item .service-icon {
  @include transition(all 0.5s ease-in-out 0s);
}

.featured-item {
  @include transition(all 0.5s ease-in-out 0s);

  .featured-icon {
    @include transition(all 0.5s ease-in-out 0s);

    i {
      @include transition(all 0.5s ease-in-out 0s);
    }
  }
}

.team-member, .team-description, .post, .post-image:after, .product-link {
  @include transition(all 0.5s ease-in-out 0s);
}

/* ------------------------
    Transform translate
------------------------*/

/*--translate-X--*/

.transform-x, #search-input-box {
  @include transform(translateX(-50%));
}

/*--translate-Y--*/

.transform-y, .align-center, .image-column {
  @include transform(translateY(-50%));
}

/*--translate-XY--*/

.video-btn-pos, .post-link {
  @include transform(translateX(-50%) translateY(-50%));
}

/* ------------------------
    Box Shadow
------------------------*/

.box-shadow, .dropdown-menu, #header-wrap.fixed-header, .navbar-nav ul, .service-item .service-desc, .featured-item.style-2, .post {
  @include box-shadow(0px 10px 60px 0px rgba(0, 0, 0, 0.08));
}

.owl-carousel {
  .post, .featured-item, .service-desc {
    @include box-shadow(0 0 22px 0 rgba(7, 7, 7, 0.08));
  }
}

.service-item.style-3 {
  @include box-shadow(0 0 22px 0 rgba(7, 7, 7, 0.08));

  .service-icon {
    @include box-shadow(0 0 22px 0 rgba(7, 7, 7, 0.08));
  }
}

/* ------------------------
    Pre Loader
------------------------*/

.clear-loader {
  background-color: $primary;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  @include transition(.6s);
  margin: 0 auto;
}

.loader {
  position: relative;
  display: inline-block;
  margin: 4em;
  width: 4em;
  height: 4em;
  @include transform-origin(center center);
  @include animation(rotate-anim 3s ease infinite);

  .plus {
    position: absolute;
    width: 4em;
    height: 1em;
    top: 1em;
    left: 0em;
    background-color: $white;
    @include transform-origin(center center);
    @include animation(plus-anim 3s linear infinite);
    @include transform(rotate(45deg));

    &:nth-of-type(2) {
      @include transform(rotate(135deg));
    }
  }

  .dot {
    position: absolute;
    width: 0.5em;
    height: 0.5em;
    left: 1.25em;
    top: 1.25em;

    &:before {
      position: absolute;
      content: "";
      width: 0.5em;
      height: 0.5em;
      left: 0;
      top: 0;
      border-radius: 50%;
      background: $white;
      @include animation(dots-anim 3s linear infinite);
    }

    &:nth-of-type(3) {
      @include transform(rotate(270deg));
    }

    &:nth-of-type(4) {
      @include transform(rotate(360deg));
    }

    &:nth-of-type(5) {
      @include transform(rotate(450deg));
    }

    &:nth-of-type(6) {
      @include transform(rotate(540deg));
    }
  }
}

/* ------------------------
    Background Overlay
------------------------*/

[data-overlay] {
  position: relative;
  z-index: 0;

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }

  &.white-overlay:before {
    background-color: $white;
  }

  &.hero-overlay:before {
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0) 100%);
  }

  &.overlay-50:before {
    width: 100%;
  }

  @media screen and (min-width: 992px) {
    &.overlay-50:before {
      width: 50%;
    }
  }

  @media screen and (min-width: 992px) {
    &.overlay-45:before {
      width: 45%;
    }
  }
}

[data-overlay="1"]:before {
  opacity: 0.1;
}

[data-overlay="2"]:before {
  opacity: 0.2;
}

[data-overlay="3"]:before {
  opacity: 0.3;
}

[data-overlay="4"]:before {
  opacity: 0.4;
}

[data-overlay="5"]:before {
  opacity: 0.5;
}

[data-overlay="6"]:before {
  opacity: 0.6;
}

[data-overlay="7"]:before {
  opacity: 0.7;
}

[data-overlay="8"]:before {
  opacity: 0.8;
}

[data-overlay="9"]:before {
  opacity: 0.9;
}

[data-overlay="10"]:before {
  opacity: 1;
}

[data-overlay="0"]:before {
  opacity: 0;
}

.img-overlay[data-overlay]:before {
  z-index: 1;
}

.theme-overlay-1 {
  position: relative;
}
.theme-overlay-1:before {
  content: '';
  background-image: url('/storage/backgrounds/hexagons-pattern-2-1.svg');
  background-position: center center;
  background-size: cover;
  background-color: $primary;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/* ------------------------
    Scroll to Top
------------------------*/

.scroll-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 600;
  opacity: 0;
  visibility: hidden;
  @include transition(all 0.8s ease-in-out);
  @include transform(translate3d(0, 200%, 0));

  a {
    display: block;
    height: 60px;
    width: 60px;
    line-height: 60px;
    text-align: center;
    @include box-shadow(0 0 15px 6px rgba(0, 0, 0, 0.1));
    @include transition(all 0.3s ease-in-out);
    font-size: 32px;
    color: $white;
    background: $primary;

    &:visited {
      display: block;
      height: 60px;
      width: 60px;
      line-height: 60px;
      text-align: center;
      @include box-shadow(0 0 15px 6px rgba(0, 0, 0, 0.1));
      @include transition(all 0.3s ease-in-out);
      font-size: 32px;
      color: $white;
      background: $primary;
    }

    &:hover, &:focus {
      color: $primary;
      background: $text-dark;
    }
  }

  &.scroll-visible {
    opacity: 1;
    visibility: visible;
    @include transform(translate3d(0, 0, 0));
  }
}

/* ------------------------
    Page Title
------------------------*/

.page-title {
  background-position: center center;
  background-size: cover;
  display: block;
  position: relative;
  padding: 100px 0;
  z-index: 1;

  h1 {
    font-size: 45px;
    font-weight: 800;
    line-height: 1.2;
  }
}

.breadcrumb {
  background: none;
  display: inline-block;
  margin: 0;
  
  color: $text-dark;
  font-weight: 600;
}

.breadcrumb-item {
  display: inline-block;

  a {
    color: $text-dark;
  }

  &.active, a:hover {
    color: $primary;
  }

  + .breadcrumb-item:before {
    color: $primary;
    content: "\e5cc";
    font-family: 'Material Icons Outlined';
    font-weight: 700;
  }
}

/* ------------------------
    Flat Icon
------------------------*/
@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"), url("../fonts/Flaticon.woff2") format("woff2"), url("../fonts/Flaticon.woff") format("woff"), url("../fonts/Flaticon.ttf") format("truetype"), url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before, [class^="flaticon-"]:after, [class*=" flaticon-"]:after {
  font-family: Flaticon;
  font-style: normal;
}

.flaticon-laboratory:before {
  content: "\f100";
}

.flaticon-flask:before {
  content: "\f101";
}

.flaticon-laboratory-1:before {
  content: "\f102";
}

.flaticon-biology:before {
  content: "\f103";
}

.flaticon-medical-laboratory:before {
  content: "\f104";
}

.flaticon-research:before {
  content: "\f105";
}

.flaticon-research-1:before {
  content: "\f106";
}

.flaticon-diabetes-test:before {
  content: "\f107";
}

.flaticon-blood:before {
  content: "\f108";
}

.flaticon-test:before {
  content: "\f109";
}

.flaticon-medical-laboratory-1:before {
  content: "\f10a";
}

.flaticon-heathcare-and-medical:before {
  content: "\f10b";
}

.flaticon-help-call:before {
  content: "\f10c";
}

.flaticon-award:before {
  content: "\f10d";
}

.flaticon-pharmacy:before {
  content: "\f10e";
}

.flaticon-biology-1:before {
  content: "\f10f";
}

.flaticon-doctor:before {
  content: "\f110";
}

.flaticon-medical-assistance:before {
  content: "\f111";
}

.flaticon-doctor-1:before {
  content: "\f112";
}

.flaticon-hospital:before {
  content: "\f113";
}

.flaticon-working-hours:before {
  content: "\f114";
}

.flaticon-medical-history:before {
  content: "\f115";
}

.flaticon-medical-report:before {
  content: "\f116";
}

.flaticon-calendar:before {
  content: "\f117";
}

.flaticon-doctor-2:before {
  content: "\f118";
}