@keyframes floating {
    0% { transform: translateY(0); }
    50% { transform: translateY(-40px); }
    100% { transform: translateY(0); }
}

@keyframes dummyDataAnimStart {
    0% { opacity: 0; transform: scale(.85); }
    25% { opacity: .33; transform: scale(1.15); }
    50% { opacity: .66; transform: scale(.95); }
    75% { opacity: 1; transform: scale(1.05); }
    100% { opacity: 1; transform: scale(1); }
}

@keyframes hiveDataAnimStart {
    0% { opacity: 0; transform: scale(1.4); }
    100% { opacity: 1; transform: scale(1); }
}

@keyframes dataAnimEnd {
    0% { opacity: 1; transform: scale(1); }
    50% { opacity: 1; transform: scale(1.1); }
    100% { opacity: 0; transform: scale(.25); }
}