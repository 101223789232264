.jobs-wrap {

    .job-item {
        margin-bottom: 3rem;
        padding-bottom: 3rem;
        text-align: center;
    }
    .job-item:not(:last-child) {
        border-bottom: 4px solid $primary-white;
    }

    .job-icon {
        margin-bottom: 1rem;
    }

    .job-suptitle {
        color: $primary;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: .1em;
    }

    .job-title {
        color: $primary;
        font-size: 18px;
        font-weight: bold;
        line-height: 1.3;
        margin-bottom: 10px;
    }

    .job-infos {
        background-color: $primary-white;
        border-radius: 5px;
        display: inline-block;
        margin-bottom: 10px;
        padding: 5px 10px;

        & > * {
            margin: 5px;
        }
    }

    .job-description {
        padding-top: 1rem;
        padding-bottom: 1rem;
        text-align: left;

        & > p {
            color: $body-color;
            line-height: 1.4;
        }
    }

    .job-buttons {
        .btn { margin-bottom: 1rem; width: 100%; }
    }

}


@media screen and (min-width: 992px) {
    .jobs-wrap {

        .job-item {
            display: flex;
            align-items: flex-start;
            text-align: left;
        }
    
        .job-icon {
            flex-shrink: 0;
            margin-right: 25px;
        }
    
        .job-title {
            font-size: 20px;
        }

        .job-content {
            flex-grow: 1;
        }
    
        .job-description {
            padding-top: 10px;
            padding-bottom: 10px;
            text-align: left;
    
            & > p {
                color: $body-color;
                font-size: 14px;
                line-height: 1.4;
            }
        }
    
        .job-buttons {
            .btn { margin-bottom: 0; margin-right: 10px; width: auto; }
        }
    
    }
}