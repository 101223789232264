.box-shadow-1 {
    box-shadow: 0px 10px 60px 0px rgb(0 0 0 / 10%);
}

.hover-op {
    transition: opacity .5s;
}
.hover-op:hover {
    opacity: .7;
}

.navbar-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
}

.overflow-h { overflow: hidden !important; }


.svg-primary * {
    fill: $primary;
}
.svg-white * {
    fill: #fff;
}
.svg-hover-white * {
    transition: fill .5s;
}
.svg-hover-white:hover * {
    fill: #fff;
}

.text-bold { font-weight: bold; }
.text-secondary { color: $secondary !important; }

.text-size-sm {
    font-size: .9rem;
}
.text-size-xs {
    font-size: .7em;
}
.text-size-xs-2 {
    font-size: .5em;
}
