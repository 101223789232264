.card {
    border: none;
    border-radius: 0;
}
.card-hexa {
    background: url('/storage/hexagon-style-bg-3.svg') center center no-repeat;
    background-size: cover;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-left: 50px;
    padding-right: 50px;
    position: relative;
    height: 330px;
    width: 300px;
}