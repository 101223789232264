
//    Mixins

@mixin transition($transition...) {
  -webkit-transition: $transition;
     -moz-transition: $transition;
       -o-transition: $transition;
          transition: $transition;
}

@mixin transition-duration($duration...) {
  -webkit-transition-duration: $duration;
     -moz-transition-duration: $duration;
       -o-transition-duration: $duration;
          transition-duration: $duration;
}

@mixin transition-delay($delay...) {
  -webkit-transition-delay: $delay;
     -moz-transition-delay: $delay;
       -o-transition-delay: $delay;
          transition-delay: $delay;
}

@mixin transition-property($property...) {
  -webkit-transition-property: $property;
     -moz-transition-property: $property;
       -o-transition-property: $property;
          transition-property: $property;
}

@mixin transition-timing-function($timing...) {
  -webkit-transition-timing-function: $timing;
     -moz-transition-timing-function: $timing;
       -o-transition-timing-function: $timing;
          transition-timing-function: $timing;
}



@mixin transform($transform) {
    -webkit-transform: $transform;
       -moz-transform: $transform;
        -ms-transform: $transform;
         -o-transform: $transform;
            transform: $transform;
}

@mixin transform-origin($value) {
    -webkit-transform-origin: $value;
       -moz-transform-origin: $value;
        -ms-transform-origin: $value;
         -o-transform-origin: $value;
            transform-origin: $value;
}


@mixin keyframes ( $animation-name ) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name}  {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation ($animation) {
  -webkit-animation: $animation;
     -moz-animation: $animation;
       -o-animation: $animation;
          animation: $animation;
}

@mixin animation-name ($name) {
  -webkit-animation-name: $name;
     -moz-animation-name: $name;
       -o-animation-name: $name;
          animation-name: $name;
}

@mixin animation-direction ($direction) {
  -webkit-animation-direction: $direction;
     -moz-animation-direction: $direction;
       -o-animation-direction: $direction;
          animation-direction: $direction;
}

@mixin animation-duration ($duration) {
  -webkit-animation-duration: $duration;
     -moz-animation-duration: $duration;
       -o-animation-duration: $duration;
          animation-duration: $duration;
}

@mixin animation-delay($delay) {
  -webkit-animation-delay: $delay;
     -moz-animation-delay: $delay;
       -o-animation-delay: $delay;
          animation-delay: $delay;
}

@mixin animation-iteration-count($count) {
  -webkit-animation-iteration-count: $count;
     -moz-animation-iteration-count: $count;
       -o-animation-iteration-count: $count;
          animation-iteration-count: $count;
}

@mixin animation-timing-function($timing) {
  -webkit-animation-timing-function: $timing;
     -moz-animation-timing-function: $timing;
       -o-animation-timing-function: $timing;
          animation-timing-function: $timing;
}


@mixin box-shadow ($shadow...) {
  -webkit-box-shadow: $shadow;
     -moz-box-shadow: $shadow;
       -o-box-shadow: $shadow;
          box-shadow: $shadow;
}