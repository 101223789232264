.nav-pills-style-1 {
    justify-content: center;
    margin-bottom: 15px;

    .nav-link {
        border: 1px solid #e9eaed;
        border-radius: 0;
        font-size: 20px;
        font-weight: 700;
        padding: 15px 30px;
        margin: 10px;
    }
}