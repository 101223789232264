.banner-slider {
    position: relative;
    z-index: 1;
    .item {
        background-size: cover;
        overflow: hidden;
    }
    .banner-title {
        font-size: 35px;
        font-weight: 800;
        line-height: 1.3;

        @media screen and (min-width: 992px) {
            font-size: 45px;
        }

        @media screen and (min-width: 1200px) {
            font-size: 55px;
        }
    }

    .banner-video {
        position: relative;

        video {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            transform: translate(-50%,-30%);
        }
    }

    
} 

.banner-image-anim {
    position: relative;

    .img-main, .img-main-sup {
        animation: floating ease-in-out 5s infinite;
    }
    .img-sup, .img-main-sup {
        position: absolute;
        top: 0;
        left: 0;
    }
    .img-sup {
        animation: floating ease-in-out 5s .75s infinite;
    }
}

.banner-hexa-anim {
    background: url('/storage/backgrounds/hexagon-full-bg-1.svg') center center no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;
    min-height: 300px;
    max-height: 300px;
    .bha-items {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
    }

    @media screen and (min-width: 576px) {
        min-height: 400px;
        max-height: 400px;
    }
    
    @media screen and (min-width: 768px) {
        min-height: 600px;
        max-height: 600px;
    }

    @media screen and (min-width: 1200px) {
        min-height: 500px;
        max-height: none;
    }

    svg {
        g[id^="data-"] {
            transform-box: fill-box;
            transform-origin: center center;
            opacity: 0;
            &.start-anim { animation: dummyDataAnimStart .75s ease-out forwards; }
            &.end-anim { 
                animation-name: dataAnimEnd;
                animation-timing-function: ease-in-out;
                animation-duration: 1s;
                animation-delay: 0s !important;
                animation-fill-mode: forwards;
            }
        }
        g[id^="hive-"] {
            transform-box: fill-box;
            transform-origin: center center;
            opacity: 0;
            &.start-anim { animation: hiveDataAnimStart 1s ease-in-out forwards; }
            &.end-anim { 
                animation-name: dataAnimEnd;
                animation-timing-function: ease-in-out;
                animation-duration: 1s;
                animation-delay: 0s !important;
                animation-fill-mode: forwards;
            }
        }
    }
}